@tailwind base;
@tailwind components;
@tailwind utilities;

@import "base";
@import "scrollbar";
@import "import-fonts";
@import "expand-clickable-area";
@import "gradient-background";
@import "buttons";
@import "modal";
@import "number-inputs";
@import "swiper";
