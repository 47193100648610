.gradient-background {
    --color-1: #23CDE8;
    --color-2: theme("colors.blue.500");
  
    background-color: var(--color-2);

  .bg {
    @apply pointer-events-none absolute left-0 top-0 w-full h-full;
    animation: pulse 4s ease-in-out infinite alternate;
  }

  .bg-1 {
    background-color: var(--color-2);
    animation-delay: 0s;
    z-index: 3;

    background-image: radial-gradient(at 67% 64%, var(--color-1) 0, transparent 49%),
        radial-gradient(at 25% 54%, var(--color-1) 0, transparent 44%),
        radial-gradient(at 30% 82%, var(--color-1) 0, transparent 43%),
        radial-gradient(at 95% 92%, var(--color-1) 0, transparent 40%),
        radial-gradient(at 31% 66%, var(--color-1) 0, transparent 41%),
        radial-gradient(at 65% 32%, var(--color-1) 0, transparent 54%);
  }

  .bg-2 {
    background-color: var(--color-2);
    animation-delay: 2s;
    z-index: 2;

    background-image: radial-gradient(at 10% 100%, var(--color-1) 0, transparent 45%),
        radial-gradient(at 83% 34%, var(--color-1) 0, transparent 50%),
        radial-gradient(at 94% 75%, var(--color-1) 0, transparent 50%),
        radial-gradient(at 80% 0%, var(--color-1) 0, transparent 50%),
        radial-gradient(at 0% 0%, var(--color-1) 0, transparent 50%);
  }
  .bg-3 {
    background-color: var(--color-2);
    animation-delay: 4s;
    z-index: 1;

    background-image: radial-gradient(at 16% 4%, var(--color-1) 0, transparent 50%),
        radial-gradient(at 72% 31%, var(--color-1) 0, transparent 44%),
        radial-gradient(at 86% 11%, var(--color-1) 0, transparent 52%),
        radial-gradient(at 57% 3%, var(--color-1) 0, transparent 49%),
        radial-gradient(at 93% 30%, var(--color-1) 0, transparent 57%),
        radial-gradient(at 89% 79%, var(--color-1) 0, transparent 45%);
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}
