@import "swiper/css";
@import "swiper/css/pagination";

.pagination-manual {
  @apply mx-auto;

  width: fit-content !important;

  --swiper-pagination-bullet-size: 1rem;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-inactive-color: theme("colors.white");
  --swiper-pagination-color: theme("colors.black");

  .swiper-pagination-bullet {
    @apply border-2 border-white;
  }
}
