@font-face {
    font-family: 'PP Neue Machina';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/PPNeueMachina-InktrapRegular.woff2') format('woff2');
}

@font-face {
    font-family: 'PP Neue Machina';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/PPNeueMachina-InktrapBold.woff2') format('woff2');
}